import React from "react"

const IndexPage = () => (
  <div>
    <p>
      <br />
      <br />
      • Sinapsis Agencia Digital, Publicidad y Marketing S.A.C., con RUC N°
      20602609937, con domicilio en Calle Rey de Bahamonde N° 111, Santiago de
      Surco, Lima, Perú. <br />
      • FIBERMAX TELECOM S.A.C, con RUC N° 20432857183, con domicilio en CAL.
      ERNESTO DIEZ CANSECO 236 INT. 403 MIRAFLORES - LIMA - LIMA <br />
      • ATELIX S.A.C, con RUC N° 20601898625, con domicilio en CAL. LAS CANTUTAS
      311 INT. 901 URB. CASUARINAS SUR I Y II ET SANTIAGO DE SURCO - LIMA -
      LIMA. <br />
      • PERU CALLS S.A.C, con RUC N° 20602733069, con domicilio en JR. REPUBLICA
      DE CHILE 155 HUAQUILLAY COMAS - LIMA - LIMA. <br />
      • KONTACTUS BPO E.I.R.L., con RUC N° 20602738770, con domicilio en CAL. 8
      MZA. A1 INT. REF DPTO. B LOTE. 34 ASC. SAN FRANCISCO CAYRAN ETAP PISO 2
      CALLE 8 MZ A1 LT 37 DPTO B SAN MARTIN DE PORRES Fecha de Vencimiento : -
      LIMA - LIMA. <br />• Dharsu E.I.R.L, con RUC N° 20557235087, con domicilio
      en en Av. 1 de mayo 2935 COO Huancayo, El Agustino, Lima, Lima.
    </p>
  </div>
)

export default IndexPage
